<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thông tin tài khoản kế toán'">
          <template v-slot:preview>
            <b-form ref="form" v-model="valid" lazy-validation class="row">
              <!-- Input session -->
              <b-container class="bv-example-row">
                <b-row>
                  <b-col md-4>
                    <b-form-group id="input-group-code">
                      <label for="input-code">Mã tài khoản:</label>
                      <b-form-input
                        size="sm"
                        id="input-code"
                        v-model="code"
                        placeholder="Nhập mã"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-code">
                      <label for="input-code">Tên tài khoản:</label>
                      <b-form-input
                        size="sm"
                        id="input-code"
                        v-model="name"
                        placeholder="Nhập tên"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-code">
                      <label for="input-code">Điện thoại:</label>
                      <b-form-input
                        size="sm"
                        id="input-code"
                        v-model="phone"
                        placeholder="Nhập điện thoại"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-code">
                      <label for="input-code">Địa chỉ:</label>
                      <b-form-input
                        size="sm"
                        id="input-code"
                        v-model="address"
                        placeholder="Nhập địa chỉ"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md-4></b-col>
                  <b-col md-4></b-col>
                  <b-col md-4></b-col>
                </b-row>
              </b-container>
            </b-form>
          </template>
          <template v-slot:foot>
            <!-- <b-col lg="4" class="pb-2"> -->
            <b-container class="bv-example-row">
              <b-row>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  type="submit"
                  @click="onSubmit"
                  >Lưu</b-button
                >
                <router-link to="/accounting/installments" tag="button">
                  <b-button
                    style="margin-left: 10px; font-weight: 600; width: 70px"
                    variant="secondary"
                    size="sm"
                    >Hủy</b-button
                  >
                </router-link>
                <b-col></b-col>
                <b-col></b-col>
                <b-col></b-col>
              </b-row>
            </b-container>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style>
.center-label label {
  margin-bottom: 0px;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';

export default {
  data() {
    return {
      id: '',
      phone: '',
      address: '',
      code: '',
      name: '',
    };
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.id = this.$route.query.id;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Dịch vụ trả góp', route: '/installments' },
      { title: 'Cập nhật dịch vụ trả góp', route: '/add-installment' },
    ]);
  },
  created() {
    this.getInstallmentById();
  },
  methods: {
    getInstallmentById: async function () {
      await ApiService.setHeader();
      await ApiService.get('installments/' + this.id).then((res) => {
        this.code = res.data.data.code;
        this.name = res.data.data.name;
        this.phone = res.data.data.phone;
        this.address = res.data.data.address;
      });
    },
    makeToastFailed(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    onSubmit: async function () {
      const payload = {
        code: this.code,
        name: this.name,
        phone: this.phone,
        address: this.address,
      };
      try {
        ApiService.put(`installments/${this.id}`, payload).then(() => {
          this.$router.push({
            name: 'list-installment',
            params: { updateInstallmentSuccess: true, message: 'Success' },
          });
        });
      } catch (error) {
        this.makeToastFailed(error);
      }
    },
  },
};
</script>

<style>
.form-group label {
  font-weight: 600;
}
</style>
